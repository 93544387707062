import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';

import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import LOADER from "../media/images/loader.gif";
import BUD_DEMO from "../media/images/bud_demo.png";
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaSearch, FaTwitter, FaUserAstronaut, FaShoppingBag, FaSignInAlt, FaCheck } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../constants';
import BusinessService from '../services/business_services'

//TRANSLATION
import { useTranslation } from 'react-i18next';

function SignIn() {
    const { t } = useTranslation();
    const [user_email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [signed, setSigned] = useState(false);
    const [token, setToken] = useState("");
    const [processing, setProcessing] = useState(false);

    const getToken = async () => {
        if (!user_email)
            toast.error('Veuillez remplir les champs requis', TOAST_OPTIONS);
        else if (!password || (password.length < 8))
            toast.error('Format du mot de passe incorrect', TOAST_OPTIONS);
        else {
            setProcessing(true)
            try {
                const status = await BusinessService.login(user_email, password, "web_browser")
                if (status) {
                    setSigned(true)
                    toast.success("Un jeton d'authentification vous a été envoyé", TOAST_OPTIONS);
                }
                else
                    toast.error('Echec de la connexion à votre compte', TOAST_OPTIONS);
            } catch (e) {
                toast.error(e.response && e.response.data ? e.response.data.message : e.message, TOAST_OPTIONS);
            }
            setProcessing(false)
        }
    }

    const signin = async () => {
        if (!user_email)
            toast.error('Veuillez remplir les champs requis', TOAST_OPTIONS);
        else if (!password || (password.length < 8))
            toast.error('Format du mot de passe incorrect', TOAST_OPTIONS);
        else if (!token || (token.length < 6))
            toast.error("Format du jeton d'authentification incorrect", TOAST_OPTIONS);
        else {
            setProcessing(true)
            try {
                const data = await BusinessService.TwoFAlogin(user_email, password, token, "web_browser")
                if (data.status) {
                    if(data.business)
                        window.location.href = "/merchants/buds"
                    else
                        window.location.href = "/search/business"
                }
                else
                    toast.error('Echec de la connexion à votre compte', TOAST_OPTIONS);
            } catch (e) {
                setSigned(false)
                toast.error(e.response && e.response.data ? e.response.data.message : e.message, TOAST_OPTIONS);
            }
            setProcessing(false)
        }
    }

    return (
        <div className="container">
            <header>
                <a href="/players" className="left_entry"><FaUserAstronaut /> Joueurs</a>
                <a href="/merchants" className="left_entry active"><FaShoppingBag /> Commerçants</a>
                <div className="sign_in" onClick={() => window.location.href = "/merchants"}>
                    Créer un compte
                </div>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <a href="https://discord.com/invite/sHbvCwhPGz" target="_blank" rel="noreferrer" className="right_entry social"><FaDiscord /></a>
                <a href="https://twitter.com/LoyalBuds" target="_blank" rel="noreferrer" className="right_entry social"><FaTwitter /></a>
                <a href="https://www.instagram.com/loyalbudsgame/" target="_blank" rel="noreferrer" className="right_entry social"><FaInstagram /></a>
                <span className="right_entry search_entry"><FaSearch /></span>
                <span className="right_entry mobile_entry active" onClick={() => window.location.href = "/merchants"}><FaShoppingBag /></span>
                <span className="right_entry mobile_entry" onClick={() => window.location.href = "/players"}><FaUserAstronaut /></span>
            </header>
            <div className="content simple">
                <span className="notice">Sign In</span>
                <h1 className="title">
                    Accéder à votre compte
                </h1>
                <p>
                    Veuillez remplir toutes les informations demandées
                </p>
                <div className="side_bud" style={{ backgroundImage: `url('${BUD_DEMO}')` }}></div>
                <div className="form">
                    {!signed ?
                        <React.Fragment>
                            <label>
                                Adresse email
                            </label>
                            <input type="email" onChange={e => setEmail(e.target.value)} />
                            <label>
                                Mot de passe <small>(min. 8 caractères)</small>
                            </label>
                            <input type="password" onChange={e => setPassword(e.target.value)} />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <label>
                                Jeton d'authentification
                            </label>
                            <input type="text" onChange={e => setToken(e.target.value)} value={token}/>
                        </React.Fragment>}
                    {processing ?
                        <img src={LOADER} width="100" alt="Running man" />
                        :
                        !signed ?
                            <div className="button" onClick={getToken}>
                                <FaSignInAlt /> S'identifier
                            </div>
                            :
                            <div className="button" onClick={signin}>
                                <FaCheck /> Valider
                            </div>
                    }
                    <p className="name">
                        <small>Vous avez oublié votre mot de passe ? <a href="/merchants/forgotten" className="highlight d-inline-block">Réinitialiser votre mot de passe</a></small>
                    </p>
                    <p className="name">
                        <small>Vous avez n'avez pas de compte ? <a href="/merchants" className="highlight d-inline-block">Créer votre compte</a></small>
                    </p>
                </div>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="copyright">
                    {t('home.website')}
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default SignIn;
